import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  environment: process.env.NODE_ENV || 'DEV',
  dsn: 'https://9e419d0c0e8f310ba398d8c3a327df86@sentry.mtt.xyz/5',
  integrations: [
    new Integrations.BrowserTracing({
      // 跟踪用户的页面导航
      tracingOrigins: ['localhost', 'https://yourdomain.com', /^\//],
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
