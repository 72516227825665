exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-area-echart-index-tsx": () => import("./../../../src/pages/components/areaEchart/index.tsx" /* webpackChunkName: "component---src-pages-components-area-echart-index-tsx" */),
  "component---src-pages-components-banner-index-tsx": () => import("./../../../src/pages/components/banner/index.tsx" /* webpackChunkName: "component---src-pages-components-banner-index-tsx" */),
  "component---src-pages-components-benefits-index-tsx": () => import("./../../../src/pages/components/benefits/index.tsx" /* webpackChunkName: "component---src-pages-components-benefits-index-tsx" */),
  "component---src-pages-components-bridge-index-tsx": () => import("./../../../src/pages/components/bridge/index.tsx" /* webpackChunkName: "component---src-pages-components-bridge-index-tsx" */),
  "component---src-pages-components-data-area-index-tsx": () => import("./../../../src/pages/components/dataArea/index.tsx" /* webpackChunkName: "component---src-pages-components-data-area-index-tsx" */),
  "component---src-pages-components-ecological-hover-item-index-tsx": () => import("./../../../src/pages/components/ecological/hoverItem/index.tsx" /* webpackChunkName: "component---src-pages-components-ecological-hover-item-index-tsx" */),
  "component---src-pages-components-ecological-index-tsx": () => import("./../../../src/pages/components/ecological/index.tsx" /* webpackChunkName: "component---src-pages-components-ecological-index-tsx" */),
  "component---src-pages-components-entry-copied-button-index-tsx": () => import("./../../../src/pages/components/entry/copiedButton/index.tsx" /* webpackChunkName: "component---src-pages-components-entry-copied-button-index-tsx" */),
  "component---src-pages-components-entry-hover-link-index-tsx": () => import("./../../../src/pages/components/entry/hoverLink/index.tsx" /* webpackChunkName: "component---src-pages-components-entry-hover-link-index-tsx" */),
  "component---src-pages-components-entry-index-tsx": () => import("./../../../src/pages/components/entry/index.tsx" /* webpackChunkName: "component---src-pages-components-entry-index-tsx" */),
  "component---src-pages-components-features-index-tsx": () => import("./../../../src/pages/components/features/index.tsx" /* webpackChunkName: "component---src-pages-components-features-index-tsx" */),
  "component---src-pages-components-governance-index-tsx": () => import("./../../../src/pages/components/governance/index.tsx" /* webpackChunkName: "component---src-pages-components-governance-index-tsx" */),
  "component---src-pages-components-helping-index-tsx": () => import("./../../../src/pages/components/helping/index.tsx" /* webpackChunkName: "component---src-pages-components-helping-index-tsx" */),
  "component---src-pages-components-network-components-col-item-index-tsx": () => import("./../../../src/pages/components/network/components/colItem/index.tsx" /* webpackChunkName: "component---src-pages-components-network-components-col-item-index-tsx" */),
  "component---src-pages-components-network-index-tsx": () => import("./../../../src/pages/components/network/index.tsx" /* webpackChunkName: "component---src-pages-components-network-index-tsx" */),
  "component---src-pages-components-pie-echart-index-tsx": () => import("./../../../src/pages/components/pieEchart/index.tsx" /* webpackChunkName: "component---src-pages-components-pie-echart-index-tsx" */),
  "component---src-pages-components-swiper-index-tsx": () => import("./../../../src/pages/components/swiper/index.tsx" /* webpackChunkName: "component---src-pages-components-swiper-index-tsx" */),
  "component---src-pages-components-technical-index-tsx": () => import("./../../../src/pages/components/technical/index.tsx" /* webpackChunkName: "component---src-pages-components-technical-index-tsx" */),
  "component---src-pages-components-what-is-mtt-components-copied-button-index-tsx": () => import("./../../../src/pages/components/whatIsMtt/components/copiedButton/index.tsx" /* webpackChunkName: "component---src-pages-components-what-is-mtt-components-copied-button-index-tsx" */),
  "component---src-pages-components-what-is-mtt-index-tsx": () => import("./../../../src/pages/components/whatIsMtt/index.tsx" /* webpackChunkName: "component---src-pages-components-what-is-mtt-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

